import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import TeamsScreen from './screens/TeamsScreen';
import ApplyScreen from './screens/ApplicationScreen';
import LoginScreen from './screens/LoginScreen';
import ApplicationsDashboard from './screens/ApplicationsDashboard';
import { AuthProvider, AuthContext, useAuth } from './context/AuthContext';
import GlobalStyle from './GlobalStyles';


const PrivateRoute = ({ element, adminOnly = false }) => {
  const { currentUser } = React.useContext(AuthContext);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (adminOnly && currentUser.role !== 'Admin') {
    return <Navigate to="/" />;
  }

  return element;
};

const App = () => {
  return (
    <>
    <GlobalStyle />
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/teams" element={<PrivateRoute element={<TeamsScreen />} />} />
          <Route path="/apply" element={<ApplyScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/applicationsdashboard" element={<PrivateRoute element={<ApplicationsDashboard />} adminOnly />} />
        </Routes>
      </Router>
    </AuthProvider>
    </>
  );
};

export default App;
