import React, { useState } from 'react';
import styled from 'styled-components';
import GlobalStyle from '../GlobalStyles'; 
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

// Styled components
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: ${({ reverse }) => reverse ? 'row-reverse' : 'row'};
  align-items: center;
  justify-content: center;
  background-color: ${({ bgColor }) => bgColor || '#ffffff'};
  color: ${({ color }) => color || '#333'};
  padding: 50px 5%;
  width: 100%;
  text-align: left;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 30px 15px;
    text-align: center;
  }
`;

const Content = styled.div`
  width: 50%;
  padding: 0 20px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    margin-top: 20px;
  }
`;

const Header = styled.h1`
  font-size: 40px;
  color: #1a1a1a;
  margin-bottom: 20px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 15px;
  }
`;

const Text = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 15px;
  }
`;

const Button = styled.button`
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 15px 30px;
  text-align: center;
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 12px;
    font-size: 16px;
  }
`;

const Image = styled.img`
  width: 50%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const ModalContent = styled.div`
  padding: 20px;
  text-align: left;
`;

const ModalHeader = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const ModalText = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
`;

const CloseButton = styled.button`
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d32f2f;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 12px;
    font-size: 16px;
  }
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

// Component
const LandingPage = () => {
  const navigate = useNavigate();  // Replace useHistory with useNavigate
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <GlobalStyle /> 
      <PageContainer>
        <Section bgColor="#e8f5e9">
          <Content>
            <Header>Turn Your Ideas into Reality</Header>
            <Text>Join forces with fellow graduates to launch your own startup.</Text>
            <Text>Build, Innovate, Succeed: Collaborate on real projects that enhance your resume and credibility.</Text>
            <Button onClick={() => navigate('/apply')}>Join Now for Early Access</Button>
          </Content>
          <Image src="/images/image4.png" alt="Team Working Together" />
        </Section>

        <Section bgColor="#ffffff">
          <Image src="/images/image2.png" alt="Discussion Among Team" />
          <Content>
            <Header>Unlock Your Potential</Header>
            <Text>Elevate your career with hands-on experience.</Text>
            <Text>Our platform forms small, curated teams of graduates to launch projects from the ground up. Combine your skills in tech, marketing, and finance to create impactful startups, share profits, and gain valuable experience. Build life-long connections and manage your projects with our resources, with guidance available as needed.</Text>
            <Button onClick={openModal}>Learn More</Button>
          </Content>
        </Section>

        <Section bgColor="#e8f5e9">
          <Content>
            <Header>Why Join Us?</Header>
            <Text>Founder Circles: Join curated groups to launch impactful projects from scratch.</Text>
            <Text>Real-World Experience: Gain hands-on skills in tech, marketing, and finance.</Text>
            <Text>Shared Success: Collaborate, share profits, and build life-long connections.</Text>
            <Text>Supportive Resources: Access tools and guidance to manage your projects with confidence.</Text>
            <Button onClick={() => navigate('/apply')}>Apply Now</Button>
          </Content>
          <Image src="/images/image3.png" alt="Team Collaboration" />
        </Section>

        <Section bgColor="#ffffff">
          <Image src="/images/image1.png" alt="Get Started" />
          <Content>
            <Header>Get Started</Header>
            <Text>Have questions or want more info? We're here to help.</Text>
            <Text>(954)-616-7003<br/>Info@Cajcode.com<br/></Text>
            <Button onClick={() => navigate('/apply')}>Reserve Your Seat</Button>
          </Content>
        </Section>
      </PageContainer>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Learn More"
        ariaHideApp={false}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '40px',
            borderRadius: '8px',
            border: '1px solid #ccc',
            maxWidth: '600px',
            width: '100%',
          },
        }}
      >
        <ModalContent>
          <ModalHeader>Why Join Us?</ModalHeader>
          <ModalText>
            By joining our platform, you'll have the opportunity to:
          </ModalText>
          <ModalText>
            <strong>Build Your Resume:</strong> Gain hands-on experience in real-world projects that will enhance your portfolio and make you stand out to potential employers.
          </ModalText>
          <ModalText>
            <strong>Get Hired:</strong> Companies are looking for candidates with practical experience. Showcase your skills and get hired based on the projects you've successfully completed.
          </ModalText>
          <ModalText>
            <strong>Start Your Own Business:</strong> Collaborate with like-minded individuals to build a startup from the ground up. Leverage our resources and guidance to turn your ideas into reality.
          </ModalText>
          <ModalActions>
            <CloseButton onClick={closeModal}>Close</CloseButton>
          </ModalActions>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LandingPage;
