import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db, storage } from '../firebase'; // Ensure storage is imported
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import Firebase Storage utilities
import styled from 'styled-components';
import Navbar from '../components/Navbar'; 
import { FiUpload } from 'react-icons/fi'; // Import upload icon

// Styled Components
const Container = styled.div`
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  background: linear-gradient(135deg, #a8e063 0%, #56ab2f 50%, #b4e197 100%);
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  font-size: 20px;
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #4CAF50;
    outline: none;
  }
`;

const TextArea = styled.textarea`
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  height: 120px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #4CAF50;
    outline: none;
  }
`;

const FileInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-bottom: 20px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: border-color 0.3s ease;

  &:hover {
    border-color: #4CAF50;
  }
`;

const FileInputLabel = styled.label`
  font-size: 16px;
  color: #666;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  margin-top: 10px;
`;

const FileInputIcon = styled(FiUpload)`
  color: #4CAF50;
  font-size: 36px;
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const FileUploadText = styled.p`
  font-size: 16px;
  color: ${({ success }) => (success ? '#4CAF50' : '#666')};
  font-family: 'Roboto', sans-serif;
  margin-top: 10px;
`;

const Button = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 12px 30px;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #45a049;
    transform: translateY(-2px);
  }

  &:active {
    background-color: #3e8e41;
    transform: translateY(0);
  }
`;

const ApplicationScreen = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    skills: '',
    motivation: '',
    password: '',
    confirmPassword: '',
  });
  const [resume, setResume] = useState(null);
  const [transcript, setTranscript] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === 'resume') {
      setResume(files[0]);
      setFileUploaded(true);
    } else if (name === 'transcript') {
      setTranscript(files[0]);
      setFileUploaded(true);
    }
  };

  const handleContainerClick = () => {
    document.getElementById('file-upload').click();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (formData.password !== formData.confirmPassword) {
      alert('Passwords do not match');
      return;
    }
  
    if (!resume && !transcript) {
      alert('Please upload either a resume or a transcript.');
      return;
    }
  
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      const user = userCredential.user;
      
      // Upload resume and/or transcript to Firebase Storage
      const resumeUrl = resume ? await uploadFile(resume, `resumes/${user.uid}/${resume.name}`) : null;
      const transcriptUrl = transcript ? await uploadFile(transcript, `transcripts/${user.uid}/${transcript.name}`) : null;
      
      // Store user details in Firestore in the applications collection
      await setDoc(doc(db, 'applications', user.uid), {
        name: formData.name,
        email: user.email,
        skills: formData.skills.split(',').map(skill => skill.trim()),
        motivation: formData.motivation,
        resumeUrl,
        transcriptUrl,
        submittedAt: serverTimestamp(),
      });
      
      // Add user to the 'users' collection as well
      await setDoc(doc(db, 'users', user.uid), {
        name: formData.name,
        email: user.email,
        skills: formData.skills.split(',').map(skill => skill.trim()),
        motivation: formData.motivation,
        role: 'Member', // Default role
        teamId: null, // Set the teamId later when added to a team
        createdAt: serverTimestamp(),
      });

      navigate('/teams');
    } catch (error) {
      console.error('Error during application:', error);
      alert('Failed to apply. Please try again.');
    }
  };

  const uploadFile = async (file, path) => {
    const storageRef = ref(storage, path);
    const snapshot = await uploadBytes(storageRef, file);
    return getDownloadURL(snapshot.ref);
  };

  return (
    <>
      <Navbar />
      <Container>
        <h1 style={{ fontFamily: 'Poppins, sans-serif', textAlign: 'center', fontSize: '28px', marginBottom: '20px' }}>Apply to Join a Team</h1>
        <Form onSubmit={handleSubmit}>
          <Title>Your Name</Title>
          <Input 
            type="text" 
            name="name" 
            placeholder="Enter your name" 
            value={formData.name} 
            onChange={handleChange} 
            required 
          />

          <Title>Your Email</Title>
          <Input 
            type="email" 
            name="email" 
            placeholder="Enter your email" 
            value={formData.email} 
            onChange={handleChange} 
            required 
          />

          <Title>Password</Title>
          <Input 
            type="password" 
            name="password" 
            placeholder="Enter your password" 
            value={formData.password} 
            onChange={handleChange} 
            required 
          />

          <Title>Confirm Password</Title>
          <Input 
            type="password" 
            name="confirmPassword" 
            placeholder="Confirm your password" 
            value={formData.confirmPassword} 
            onChange={handleChange} 
            required 
          />

          <Title>Skills</Title>
          <TextArea 
            name="skills" 
            placeholder="Describe your skills (e.g., programming, finance, marketing)" 
            value={formData.skills} 
            onChange={handleChange} 
            required 
          />

          <Title>Motivation</Title>
          <TextArea 
            name="motivation" 
            placeholder="Why do you want to join a team?" 
            value={formData.motivation} 
            onChange={handleChange} 
            required 
          />

          <Title>Upload Resume or Transcript</Title>
          <FileInputContainer onClick={handleContainerClick}>
            <FileInputIcon />
            <FileInputLabel htmlFor="file-upload">
              Drag and drop your resume or transcript here, or click to browse
            </FileInputLabel>
            <HiddenFileInput 
              id="file-upload"
              type="file" 
              name="resume" 
              accept=".pdf,.doc,.docx" 
              onChange={handleFileChange} 
            />
            {fileUploaded && (
              <FileUploadText success>
                File uploaded successfully!
              </FileUploadText>
            )}
          </FileInputContainer>

          <Button type="submit">Submit Application</Button>
        </Form>
      </Container>
    </>
  );
};

export default ApplicationScreen;
